import * as React from "react";
import Layout from "../components/layouts/Layout";
import backgroundHex from "../assets/img/backgroundSections/background.webp";
import InventoryWaifuComponent from "../components/InventoryWaify/InventoryWaify";

// markup
const InventoryWaifu = () => {

  return (
    <Layout background={backgroundHex}>
      <InventoryWaifuComponent />
    </Layout>
  )
}

export default InventoryWaifu
