import React, { useState, useContext, useEffect } from 'react'
import { useForm } from "react-hook-form";
import start1 from "../../assets/vector/startWaifu1.svg"
import start2 from "../../assets/vector/startWaifu2.svg"
import start3 from "../../assets/vector/startWaifu3.svg"
import start4 from "../../assets/vector/startWaifu4.svg"
import start5 from "../../assets/vector/startWaifu5.svg"
import WaifuCardItem from '../elements/WaifuCardItem';
import loadIcon from "../../assets/img/loadingIcon.webp";
import waifuMintIcon from "../../assets/vector/waifuMintIcons/waifuMintIcon.svg";
import PaginationComponent from '../elements/PaginationComponent';
import UserContext from '../../context/userContext/UserContext';
import SellNft from '../SellNft/SellNft';
import BurnNft from '../BurnNft/BurnNft';
import MintQuick from '../elements/mintQuick';
import GetImageAdn from '../elements/getImageAdn';
import CrewCard from '../CrewCard';


export default function InventoryWaifu() {
    const [listItemsFilter, setListItemsFilter] = useState(null)
    const [listItems, setListItems] = useState(null)
    const [filterStart, setFilterStart] = useState("all")
    const [reloadData, setReloadData] = useState(true)
    const [selectStar, setSelectStar] = useState(0)
    const stars_list = ['#', start1, start2, start3, start4, start5];
    const [filtered, setFiltered] = useState(false);

    const [modalNft, setModalNft] = useState(false)
    const [modalNftBurn, setModalNftBurn] = useState(false)

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { contract, account, chainId } = useContext(UserContext);

    useEffect(() => {

        let listLocalStorage = JSON.parse(localStorage.getItem("WaifusStorage"))

        if (listLocalStorage && listLocalStorage.account === account) {

            setListItems(listLocalStorage.listWaifus || null)
            setListItemsFilter(listLocalStorage.listWaifus || null)

        }
    }, [])

    useEffect(() => { if (!reloadData) setReloadData(true) }, [chainId])

    useEffect(() => {
        if (contract && account && reloadData) {
            setReloadData(false)
            contract.contractWaifu.methods.getFarmerIdsOf(account).call().then(result => {

                const getAllWaifus = []
                result.map(element => { if (element !== "1000000000000000000") return getAllWaifus.push(contract.contractWaifu.methods.getFarmer(element).call()) })
                Promise.all(getAllWaifus).then(result => {
                    const listAllWaifus = result.reverse().map(element => ({ adn: '0'.repeat(61 - element.dna.length) + element.dna, ...element, waifuPower: Math.max(...element.waifuPowers) }))

                    setListItems(listAllWaifus)
                    setListItemsFilter(listAllWaifus)
                    localStorage.setItem("WaifusStorage", JSON.stringify({ listWaifus: listAllWaifus, account }));
                }).catch(err => {
                    console.error(err)
                    setListItems([])
                    setListItemsFilter([]);
                })
            })
        }
    }, [contract, account, reloadData]);

    const handleFilterStart = (value) => {
        setFiltered(true)
        if (value === "all") return setListItemsFilter(listItems)
        else if (value === "1") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 49))
        else if (value === "2") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 99 && parseInt(element.waifuPower) > 49))
        else if (value === "3") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 149 && parseInt(element.waifuPower) > 99))
        else if (value === "4") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 199 && parseInt(element.waifuPower) > 149))
        else if (value === "5") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 250 && parseInt(element.waifuPower) > 199))

    }
    const HaventWaifus = () => (
        <div className="grid grid-rows-mint justify-center text-center mt-8">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-52 mx-auto self-center"></img>
            <span className="font-bold text-base text-black">It seems like you don't have any farmer waifus!</span>
            <span className="text-sm text-black">After you have minted your Farmer Waifus will appear here</span>
            <div className="p-3 bg-[#F0C1DC] w-34 rounded-md mx-auto mt-5">

                <button onClick={() => { if (typeof window !== 'undefined') window.location.href = "/mint" }} className="bg-[#AA2E74] w-34 px-3 mx-auto text-white font-bold text-base rounded-md">
                    GO TO MINT
                </button>
            </div>
        </div>
    );


    const Not_Filter = () => (
        <div className="grid grid-rows-mint justify-center text-center ">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-52 mx-auto self-center"></img>
            <div className="font-bold text-base text-black flex justify-center items-center p-0">
                <span className="mr-2">You don't have Waifus {(selectStar !== 0) && 'from'}</span>
                {(selectStar !== 0) && <img className='w-10' src={stars_list[selectStar]} alt="star" />}
            </div>
            <span className=" text-sm text-black">After you have minted your Farmer Waifus will appear here</span>
            <div className="p-3 bg-[#F0C1DC] w-34 rounded-md mx-auto mt-5">

                <button onClick={() => { if (typeof window !== 'undefined') window.location.href = "/mint" }} className="bg-[#AA2E74] w-34 px-3 mx-auto text-white font-bold text-base rounded-md">
                    GO TO MINT
                </button>
            </div>
        </div>
    );



    return (
        <>
            <div className={`p-5 relative `}>
                <SellNft setReloadData={setReloadData} modal={modalNft} setModal={setModalNft} />
                <BurnNft setReloadData={setReloadData} modal={modalNftBurn} setModal={setModalNftBurn} />

                {listItemsFilter &&
                    <section className="flex justify-between flex-col md:flex-row">
                        {/*<MintQuick mint_type="waifu" set_reload_data={setReloadData} items={listItems} />*/}
                        <div>
                            <h3 className="pb-2 text-lg mt-12 md:mt-0 text-center md:text-left"> <strong>Filter by Waifu Rarity:</strong>  </h3>
                            <div className=" flex justify-center items-center">
                                <button onClick={() => { handleFilterStart("all"); setSelectStar(0) }} className={`${selectStar == 0 ? " bg-primaryDark " : "bg-background"} w-10  rounded-md text-white h-10  active:scale-90`}>All</button>
                                <img onClick={() => { handleFilterStart("1"); setSelectStar(1) }} src={start1} alt="start-1" className={`${selectStar == 1 ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
                                <img onClick={() => { handleFilterStart("2"); setSelectStar(2) }} src={start2} alt="start-2" className={`${selectStar == 2 ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
                                <img onClick={() => { handleFilterStart("3"); setSelectStar(3) }} src={start3} alt="start-3" className={`${selectStar == 3 ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
                                <img onClick={() => { handleFilterStart("4"); setSelectStar(4) }} src={start4} alt="start-4" className={`${selectStar == 4 ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
                                <img onClick={() => { handleFilterStart("5"); setSelectStar(5) }} src={start5} alt="start-5" className={`${selectStar == 5 ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
                            </div>
                        </div>
                    </section>
                }
                {!listItemsFilter
                    ? <img src={loadIcon} className="m-auto mt-[4rem] w-16 animate-spin" alt='loading' />
                    : <section className=" py-10"> {listItemsFilter.length > 0 ? <PaginationComponent setModalNftBurn={setModalNftBurn} setModalNft={setModalNft} item={"waifuInventory"} listItems={listItemsFilter} paginationCant={12} /> : <> {filtered ? <Not_Filter /> : <HaventWaifus />} </>}  </section>
                }

            </div>
        </>
    )
}